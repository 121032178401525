import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  initSearchBookStoreState,
  setSearchBookStore,
} from "stores/searchBookStore";

const useSearchBookInit = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (
      !(
        pathname.includes("/book/master/search") ||
        pathname.includes("/book/master/register")
      )
    ) {
      setSearchBookStore(initSearchBookStoreState);
    }
  }, [pathname]);
};

export default useSearchBookInit;
