import React, { ReactNode, useEffect } from "react";
import useNavigation from "hooks/useNavigation";
import { useSessionStorage } from "hooks/useSessionStorage";

interface SignedOutContainerProps {
  children: ReactNode;
}

/* 유저 정보가 있을 때 로그인, 회원가입 등의 페이지 접근을 막는다. */
const SignedOutContainer = ({ children }: SignedOutContainerProps) => {
  const { getItem } = useSessionStorage();
  const { navigate } = useNavigation();
  useEffect(() => {
    if (getItem("user")) {
      navigate("/");
    }
  }, [getItem, navigate]);
  return <>{children}</>;
};

export default SignedOutContainer;
