import AsyncContainer from "components/Containers/AsyncContainer";
import LoadingSpinner from "components/LoadingSpinner";
import PageContainer from "components/PageContainer/PageContainer";
import SignedInContainer from "components/PageContainer/SignedInContainer";
import SignedOutContainer from "components/PageContainer/SignedOutContainer";
import React, { lazy, LazyExoticComponent, MemoExoticComponent } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import type { RouteCondition, URLPaths } from "types/urls";
import URLs from "./URLConfig";

interface RouteType {
  path: URLPaths;
  component:
    | LazyExoticComponent<() => JSX.Element>
    | LazyExoticComponent<MemoExoticComponent<() => JSX.Element>>;
  routeCondition: RouteCondition;
  cssId: string;
  title: string;
}

// 코드 스플리팅을 위한 lazy import
// 정의했지만 모달로 구현하여 사용하지 않는 page가 생길 수 있음.
const InvalidApproach = lazy(() => import("pages/InvalidApproach"));
const Main = lazy(() => import("pages/Main/Main"));
const SignIn = lazy(() => import("pages/SignIn/SignIn"));

// 통합도서정보 관리
// 도서마스터
const BookSearch = lazy(() => import("pages/Book/Master/BookSearch"));
const BookRegister = lazy(() => import("pages/Book/Master/BookRegister"));
const BookModifyRequest = lazy(
  () => import("pages/Book/Master/BookModifyRequest"),
);
const BookRecommendedInstitutions = lazy(
  () => import("pages/Book/Master/BookRecommendedInstitutions"),
);
const BookRecommendedInstitutionsRegister = lazy(
  () => import("pages/Book/Master/BookRecommendedInstitutionsRegister"),
);
const BookReviewManagement = lazy(
  () => import("pages/Book/Master/BookReviewManagement"),
);
const BookReviewForbiddenWord = lazy(
  () => import("pages/Book/Master/BookReviewForbiddenWord"),
);
// 저자마스터
const BookAuthorManagement = lazy(
  () => import("pages/Book/Author/BookAuthorManagement"),
);

// 홈페이지관리
// 게시판
const HomeNotice = lazy(() => import("pages/Home/Board/HomeNotice"));
const HomeNoticeRegister = lazy(
  () => import("pages/Home/Board/HomeNoticeRegister"),
);
const HomeNews = lazy(() => import("pages/Home/Board/HomeNews"));
const HomeNewsRegister = lazy(
  () => import("pages/Home/Board/HomeNewsRegister"),
);
const HomeReferenceLibrary = lazy(
  () => import("pages/Home/Board/HomeReferenceLibrary"),
);
const HomeReferenceLibraryRegister = lazy(
  () => import("pages/Home/Board/HomeReferenceLibraryRegister"),
);

// 조합소개
const HomeExecutivesAndEmployees = lazy(
  () => import("pages/Home/Introduction/HomeExecutivesAndEmployees"),
);
const HomeExecutivesAndEmployeesRegister = lazy(
  () => import("pages/Home/Introduction/HomeExecutivesAndEmployeesRegister"),
);
const HomeHistory = lazy(() => import("pages/Home/Introduction/HomeHistory"));
const HomeHistoryRegister = lazy(
  () => import("pages/Home/Introduction/HomeHistoryRegister"),
);

// 조합원 안내
const HomeMember = lazy(() => import("pages/Home/Member/HomeMember"));
const HomeMemberRegister = lazy(
  () => import("pages/Home/Member/HomeMemberRegister"),
);
const HomeCooperativeMember = lazy(
  () => import("pages/Home/Member/HomeCooperativeMember"),
);
const HomeCooperativeMemberRegister = lazy(
  () => import("pages/Home/Member/HomeCooperativeMemberRegister"),
);

// 공통설정
const HomeContacts = lazy(() => import("pages/Home/Common/HomeContacts"));
const HomeContactsModify = lazy(
  () => import("pages/Home/Common/HomeContactsModify"),
);
const HomeFiles = lazy(() => import("pages/Home/Common/HomeFiles"));
const HomeFilesUpload = lazy(() => import("pages/Home/Common/HomeFilesUpload"));

// 출판사전용공간
// 공지사항관리
const PubNotice = lazy(() => import("pages/Pub/Notice/PubNotice"));
const PubNoticeRegister = lazy(
  () => import("pages/Pub/Notice/PubNoticeRegister"),
);
// 보도자료관리
const PubPressRelease = lazy(
  () => import("pages/Pub/PressRelease/PubPressRelease"),
);
// 담당자정보
const PubManagerInfo = lazy(
  () => import("pages/Pub/ManagerInfo/PubManagerInfo"),
);

// 서점전용공간
// 공지사항
const StoreNotice = lazy(() => import("pages/Store/Notice/StoreNotice"));
const StoreNoticeRegister = lazy(
  () => import("pages/Store/Notice/StoreNoticeRegister"),
);
// 반입관리
const StoreSearchReturnOut = lazy(
  () => import("pages/Store/Return/StoreSearchReturnOut"),
);
const StoreSearchReturnIn = lazy(
  () => import("pages/Store/Return/StoreSearchReturnIn"),
);
const StoreRegisterReturnIn = lazy(
  () => import("pages/Store/Return/StoreRegisterReturnIn"),
);
//1:1문의
const StoreOneToOne = lazy(() => import("pages/Store/OneToOne/StoreOneToOne"));
// 신간도서목록
const StoreNewBookList = lazy(
  () => import("pages/Store/NewBook/StoreNewBookList"),
);
const StoreNewBookListRegister = lazy(
  () => import("pages/Store/NewBook/StoreNewBookListRegister"),
);
//주문진행현황
const StoreSearchOrder = lazy(
  () => import("pages/Store/Order/StoreSearchOrder"),
);
const StoreSearchForwarding = lazy(
  () => import("pages/Store/Order/StoreSearchForwarding"),
);

// 책광장모두
// 노출설정
const ModooExposureMain = lazy(
  () => import("pages/Modoo/Exposure/Main/ModooExposureMain"),
);
const ModooExposureCategory = lazy(
  () => import("pages/Modoo/Exposure/Category/ModooExposureCategory"),
);
const ModooExposureRecommend = lazy(
  () => import("pages/Modoo/Exposure/Recommend/ModooExposureRecommend"),
);
// 기획전/이벤트
const ModooExhibition = lazy(
  () => import("pages/Modoo/Events/ModooExhibition"),
);
const ModooExhibitionRegister = lazy(
  () => import("pages/Modoo/Events/ModooExhibitionRegister"),
);
const ModooEvent = lazy(() => import("pages/Modoo/Events/ModooEvent"));
const ModooEventRegister = lazy(
  () => import("pages/Modoo/Events/ModooEventRegister"),
);
//입점몰관리
const ModooMallManagement = lazy(
  () => import("pages/Modoo/Mall/ModooMallManagement"),
);
// 문의관리
const ModooOneToOne = lazy(() => import("pages/Modoo/Inquiry/ModooOneToOne"));
const ModooFAQ = lazy(() => import("pages/Modoo/Inquiry/ModooFAQ"));
// 공지사항관리
const ModooNotice = lazy(() => import("pages/Modoo/Notice/ModooNotice"));
const ModooNoticeRegister = lazy(
  () => import("pages/Modoo/Notice/ModooNoticeRegister"),
);
// 정책관리
const ModooTerms = lazy(() => import("pages/Modoo/Policy/ModooTerms"));
const ModooDelivery = lazy(() => import("pages/Modoo/Policy/ModooDelivery"));
const ModooSaveDiscountCalculate = lazy(
  () => import("pages/Modoo/Policy/ModooSaveDiscountCalculate"),
);
const ModooCoupon = lazy(() => import("pages/Modoo/Policy/ModooCoupon"));
// 주문관리
const ModooOrderStatus = lazy(
  () => import("pages/Modoo/Order/ModooOrderStatus"),
);
const ModooOrderDetail = lazy(
  () => import("pages/Modoo/Order/ModooOrderDetail"),
);
const ModooCancelStatus = lazy(
  () => import("pages/Modoo/Order/ModooCancelStatus"),
);
const ModooCancelDetail = lazy(
  () => import("pages/Modoo/Order/ModooCancelDetail"),
);
const ModooReturnStatus = lazy(
  () => import("pages/Modoo/Order/ModooReturnStatus"),
);
const ModooReturnDetail = lazy(
  () => import("pages/Modoo/Order/ModooReturnDetail"),
);
const ModooExchangeStatus = lazy(
  () => import("pages/Modoo/Order/ModooExchangeStatus"),
);
const ModooExchangeDetail = lazy(
  () => import("pages/Modoo/Order/ModooExchangeDetail"),
);
// 매출현황
const ModooSales = lazy(() => import("pages/Modoo/Sales/ModooSales"));
const ModooDaySalesAll = lazy(
  () => import("pages/Modoo/Sales/ModooDaySalesAll"),
);
const ModooDaySalesSeparation = lazy(
  () => import("pages/Modoo/Sales/ModooDaySalesSeparation"),
);
const ModooDaySalesClient = lazy(
  () => import("pages/Modoo/Sales/ModooDaySalesClient"),
);
const ModooDaySalesProduct = lazy(
  () => import("pages/Modoo/Sales/ModooDaySalesProduct"),
);
const ModooDaySalesOrderSeq = lazy(
  () => import("pages/Modoo/Sales/ModooDaySalesOrderSeq"),
);
const ModooMonthSalesAll = lazy(
  () => import("pages/Modoo/Sales/ModooMonthSalesAll"),
);
const ModooMonthSalesSeparation = lazy(
  () => import("pages/Modoo/Sales/ModooMonthSalesSeparation"),
);
const ModooMonthSalesClient = lazy(
  () => import("pages/Modoo/Sales/ModooMonthSalesClient"),
);
const ModooMonthSalesProfit = lazy(
  () => import("pages/Modoo/Sales/ModooMonthSalesProfit"),
);
const ModooMonthFundSaving = lazy(
  () => import("pages/Modoo/Sales/ModooMonthFundSaving"),
);
const ModooMallMonthSettlement = lazy(
  () => import("pages/Modoo/Sales/ModooMallMonthSettlement"),
);
const ModooMallDaySettlement = lazy(
  () => import("pages/Modoo/Sales/ModooMallDaySettlement"),
);
// 회원관리
const ModooMember = lazy(() => import("pages/Modoo/Member/ModooMember"));
const ModooMemberDetail = lazy(
  () => import("pages/Modoo/Member/ModooMemberDetail"),
);

// 모두같이
// 기본설정
const MallSetting = lazy(() => import("pages/Mall/Setting/MallSetting"));
//메인노출설정
const MallMain = lazy(() => import("pages/Mall/Exposure/MallMain"));
//커뮤니티관리
const MallStory = lazy(() => import("pages/Mall/Community/MallStory"));
const MallStoryRegister = lazy(
  () => import("pages/Mall/Community/MallStoryRegister"),
);
const MallChannel = lazy(() => import("pages/Mall/Community/MallChannel"));
const MallChannelRegister = lazy(
  () => import("pages/Mall/Community/MallChannelRegister"),
);
const MallNews = lazy(() => import("pages/Mall/Community/MallNews"));
const MallNewsRegister = lazy(
  () => import("pages/Mall/Community/MallNewsRegister"),
);
//정산내역조회
const MallSearchBalance = lazy(
  () => import("pages/Mall/Balance/MallSearchBalance"),
);
//마케팅자료관리
const MallMarketing = lazy(() => import("pages/Mall/Marketing/MallMarketing"));
const MallMarketingRegister = lazy(
  () => import("pages/Mall/Marketing/MallMarketingRegister"),
);
// 회원관리
const MallMember = lazy(() => import("pages/Mall/Member/MallMember"));
const MallMemberDetail = lazy(
  () => import("pages/Mall/Member/MallMemberDetail"),
);

// 공통관리
// 회사정보관리
const CommonCompanyInfo = lazy(
  () => import("pages/Common/Company/CommonCompanyInfo"),
);
//임직원정보관리
const CommonEmployee = lazy(
  () => import("pages/Common/Employee/CommonEmployee"),
);
const CommonEmployeeRegister = lazy(
  () => import("pages/Common/Employee/CommonEmployeeRegister"),
);
const CommonRegisterAuthority = lazy(
  () => import("pages/Common/Employee/CommonRegisterAuthority"),
);
//팝업관리
const CommonPopup = lazy(() => import("pages/Common/Popup/CommonPopup"));
const CommonPopupRegister = lazy(
  () => import("pages/Common/Popup/CommonPopupRegister"),
);
//SCM비밀번호초기화
const CommonScmPasswordReset = lazy(
  () => import("pages/Common/ScmPassword/CommonScmPasswordReset"),
);

export const routes: RouteType[] = [
  // 메인 페이지
  {
    path: URLs.Main,
    component: Main,
    routeCondition: "WHENEVER", // 메인페이지는 서점 SCM에서 넘어오는 특수 케이스 때문에 로그인 판별을 Main 컴포넌트에서 따로 한다.
    cssId: "main",
    title: "출판협동조합 관리자",
  },
  // 로그인
  {
    path: URLs.SignIn,
    component: SignIn,
    routeCondition: "MUST_BE_SIGNED_OUT",
    cssId: "sign-in",
    title: "로그인",
  },
  // 통합도서정보관리
  // 도서마스터
  {
    path: URLs.BookSearch,
    component: BookSearch,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "book-search",
    title: "도서전체조회",
  },
  {
    path: URLs.BookRegister,
    component: BookRegister,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "book-register",
    title: "도서신규등록",
  },
  {
    path: URLs.BookModifyRequest,
    component: BookModifyRequest,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "book-modify-request",
    title: "수정요청(출판사)",
  },
  {
    path: URLs.BookRecommendedInstitutions,
    component: BookRecommendedInstitutions,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "book-recommended-institutions",
    title: "추천기관/수상",
  },
  {
    path: URLs.BookRecommendedInstitutionsRegister,
    component: BookRecommendedInstitutionsRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "book-recommended-institutions-register",
    title: "추천기관/수상 도서 등록/수정",
  },
  {
    path: URLs.BookReviewManagement,
    component: BookReviewManagement,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "book-review-management",
    title: "리뷰관리",
  },
  {
    path: URLs.BookReviewForbiddenWord,
    component: BookReviewForbiddenWord,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "book-review-forbidden-word",
    title: "리뷰 금지어 설정",
  },
  // 저자마스터
  {
    path: URLs.BookAuthorManagement,
    component: BookAuthorManagement,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "book-author-management",
    title: "저자관리",
  },
  // 홈페이지관리
  // 게시판
  {
    path: URLs.HomeNotice,
    component: HomeNotice,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-notice",
    title: "공지사항",
  },
  {
    path: URLs.HomeNoticeRegister,
    component: HomeNoticeRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-notice-register",
    title: "공지사항 등록/수정",
  },
  {
    path: URLs.HomeNews,
    component: HomeNews,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-news",
    title: "조합원소식",
  },
  {
    path: URLs.HomeNewsRegister,
    component: HomeNewsRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-news-register",
    title: "조합원소식 등록/수정",
  },
  {
    path: URLs.HomeReferenceLibrary,
    component: HomeReferenceLibrary,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-reference-library",
    title: "자료실",
  },
  {
    path: URLs.HomeReferenceLibraryRegister,
    component: HomeReferenceLibraryRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-reference-library-register",
    title: "자료실 등록/수정",
  },
  // 조합소개
  {
    path: URLs.HomeExecutivesAndEmployees,
    component: HomeExecutivesAndEmployees,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-executives-and-employees",
    title: "임직원",
  },
  {
    path: URLs.HomeExecutivesAndEmployeesRegister,
    component: HomeExecutivesAndEmployeesRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-executives-and-employees-register",
    title: "임직원 등록 수정",
  },
  {
    path: URLs.HomeHistory,
    component: HomeHistory,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-history",
    title: "연혁",
  },
  {
    path: URLs.HomeHistoryRegister,
    component: HomeHistoryRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-history-register",
    title: "연혁 등록/수정",
  },
  // 조합원 안내
  {
    path: URLs.HomeMember,
    component: HomeMember,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-member",
    title: "조합원",
  },
  {
    path: URLs.HomeMemberRegister,
    component: HomeMemberRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-member-register",
    title: "조합원 등록/수정",
  },
  {
    path: URLs.HomeCooperativeMember,
    component: HomeCooperativeMember,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-cooperative-member",
    title: "일원화회원",
  },
  {
    path: URLs.HomeCooperativeMemberRegister,
    component: HomeCooperativeMemberRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-cooperative-member-register",
    title: "일원화회원 등록/수정",
  },
  //공통설정
  {
    path: URLs.HomeContacts,
    component: HomeContacts,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-contacts",
    title: "연락처관리",
  },
  {
    path: URLs.HomeContactsModify,
    component: HomeContactsModify,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-contacts-modify",
    title: "연락처수정",
  },
  {
    path: URLs.HomeFiles,
    component: HomeFiles,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "home-files",
    title: "파일관리",
  },
  {
    path: URLs.HomeFilesUpload,
    component: HomeFilesUpload,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "home-files-upload",
    title: "파일업로드",
  },
  // 출판사전용공간
  // 공지사항관리
  {
    path: URLs.PubNotice,
    component: PubNotice,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "pub-notice",
    title: "공지사항관리",
  },
  {
    path: URLs.PubNoticeRegister,
    component: PubNoticeRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "pub-notice-register",
    title: "공지사항 등록/수정",
  },
  //보도자료관리
  {
    path: URLs.PubPressRelease,
    component: PubPressRelease,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "pub-press-release",
    title: "보도자료관리",
  },
  //담당자정보
  {
    path: URLs.PubManagerInfo,
    component: PubManagerInfo,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "pub-manager-info",
    title: "담당자정보",
  },
  // 서점전용공간
  // 공지사항
  {
    path: URLs.StoreNotice,
    component: StoreNotice,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-notice",
    title: "공지사항",
  },
  {
    path: URLs.StoreNoticeRegister,
    component: StoreNoticeRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "store-notice-register",
    title: "공지사항 등록/수정",
  },
  //반입관리
  {
    path: URLs.StoreSearchReturnOut,
    component: StoreSearchReturnOut,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-search-return-out",
    title: "반출요청조회",
  },
  {
    path: URLs.StoreSearchReturnIn,
    component: StoreSearchReturnIn,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-search-return-in",
    title: "반입요청조회",
  },
  {
    path: URLs.StoreRegisterReturnIn,
    component: StoreRegisterReturnIn,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-register-return-in",
    title: "반입요청등록",
  },
  //1:1문의
  {
    path: URLs.StoreOneToOne,
    component: StoreOneToOne,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-one-to-one",
    title: "1:1문의",
  },
  //신간도서목록
  {
    path: URLs.StoreNewBookList,
    component: StoreNewBookList,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-new-book-list",
    title: "신간도서목록",
  },
  {
    path: URLs.StoreNewBookListRegister,
    component: StoreNewBookListRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "store-new-book-list-register",
    title: "신간도서 등록/수정",
  },
  //주문진행현황
  {
    path: URLs.StoreSearchOrder,
    component: StoreSearchOrder,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-search-order",
    title: "주문",
  },
  {
    path: URLs.StoreSearchForwarding,
    component: StoreSearchForwarding,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "store-search-forwarding",
    title: "출고",
  },
  // 책광장모두
  // 노출설정
  {
    path: URLs.ModooExposureMain,
    component: ModooExposureMain,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-exposure-main",
    title: "메인",
  },
  {
    path: URLs.ModooExposureCategory,
    component: ModooExposureCategory,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-exposure-category",
    title: "분야별도서",
  },
  {
    path: URLs.ModooExposureRecommend,
    component: ModooExposureRecommend,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-exposure-recommend",
    title: "추천도서",
  },
  // 기획전/이벤트
  {
    path: URLs.ModooExhibition,
    component: ModooExhibition,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-exhibition",
    title: "기획전",
  },
  {
    path: URLs.ModooExhibitionRegister,
    component: ModooExhibitionRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-exhibition-register",
    title: "기획전등록",
  },
  {
    path: URLs.ModooEvent,
    component: ModooEvent,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-event",
    title: "이벤트",
  },
  {
    path: URLs.ModooEventRegister,
    component: ModooEventRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-event-register",
    title: "이벤트등록",
  },
  // 입점몰관리
  {
    path: URLs.ModooMallManagement,
    component: ModooMallManagement,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-mall-management",
    title: "입점몰관리",
  },
  // 문의관리
  {
    path: URLs.ModooOneToOne,
    component: ModooOneToOne,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-one-to-one",
    title: "1:1문의",
  },
  {
    path: URLs.ModooFAQ,
    component: ModooFAQ,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-faq",
    title: "FAQ",
  },
  // 공지사항관리
  {
    path: URLs.ModooNotice,
    component: ModooNotice,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-notice",
    title: "공지사항관리",
  },
  {
    path: URLs.ModooNoticeRegister,
    component: ModooNoticeRegister,
    routeCondition: "WHENEVER",
    cssId: "modoo-notice-register",
    title: "공지사항 등록/수정",
  },
  // 정책관리
  {
    path: URLs.ModooTerms,
    component: ModooTerms,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-terms",
    title: "약관",
  },
  {
    path: URLs.ModooDelivery,
    component: ModooDelivery,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-delivery",
    title: "배송비",
  },
  {
    path: URLs.ModooSaveDiscountCalculate,
    component: ModooSaveDiscountCalculate,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-save-discount-calculate",
    title: "적립/할인/정산",
  },
  {
    path: URLs.ModooCoupon,
    component: ModooCoupon,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-coupon",
    title: "쿠폰",
  },
  //주문관리
  {
    path: URLs.ModooOrderStatus,
    component: ModooOrderStatus,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-order-status",
    title: "주문현황",
  },
  {
    path: URLs.ModooOrderDetail,
    component: ModooOrderDetail,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-order-detail",
    title: "주문현황 상세",
  },
  {
    path: URLs.ModooCancelStatus,
    component: ModooCancelStatus,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-cancel-status",
    title: "취소현황",
  },
  {
    path: URLs.ModooCancelDetail,
    component: ModooCancelDetail,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-cancel-detail",
    title: "취소현황 상세",
  },
  {
    path: URLs.ModooReturnStatus,
    component: ModooReturnStatus,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-return-status",
    title: "반품현황",
  },
  {
    path: URLs.ModooReturnDetail,
    component: ModooReturnDetail,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-return-detail",
    title: "반품현황상세",
  },
  {
    path: URLs.ModooExchangeStatus,
    component: ModooExchangeStatus,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-exchange-status",
    title: "교환현황",
  },
  {
    path: URLs.ModooExchangeDetail,
    component: ModooExchangeDetail,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-exchange-detail",
    title: "교환현황 상세",
  },
  //매출현황
  {
    path: URLs.ModooSales,
    component: ModooSales,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-sales",
    title: "매출현황",
  },
  {
    path: URLs.ModooDaySalesAll,
    component: ModooDaySalesAll,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-day-sales-all",
    title: "전체 일별 매출현황",
  },
  {
    path: URLs.ModooDaySalesSeparation,
    component: ModooDaySalesSeparation,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-day-sales-separation",
    title: "구분 일별매출현황",
  },
  {
    path: URLs.ModooDaySalesClient,
    component: ModooDaySalesClient,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-day-sales-client",
    title: "거래처 일별매출현황",
  },
  {
    path: URLs.ModooDaySalesProduct,
    component: ModooDaySalesProduct,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-day-sales-product",
    title: "품목 일별매출현황",
  },
  {
    path: URLs.ModooDaySalesOrderSeq,
    component: ModooDaySalesOrderSeq,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-day-sales-order-seq",
    title: "주문번호 일별 매출현황",
  },
  {
    path: URLs.ModooMonthSalesAll,
    component: ModooMonthSalesAll,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-month-sales-all",
    title: "전체 월별매출현황",
  },
  {
    path: URLs.ModooMonthSalesSeparation,
    component: ModooMonthSalesSeparation,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-month-sales-separation",
    title: "구분 월별매출현황",
  },
  {
    path: URLs.ModooMonthSalesClient,
    component: ModooMonthSalesClient,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-month-sales-client",
    title: "거래처 월별매출현황",
  },
  {
    path: URLs.ModooMonthSalesProfit,
    component: ModooMonthSalesProfit,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-month-sales-profit",
    title: "월별 판매이익현황",
  },
  {
    path: URLs.ModooMonthFundSaving,
    component: ModooMonthFundSaving,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-month-fund-saving",
    title: "월별 기금적립현황",
  },
  {
    path: URLs.ModooMallMonthSettlement,
    component: ModooMallMonthSettlement,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-mall-settlement",
    title: "판매현황 입점몰 정산(월별)",
  },
  {
    path: URLs.ModooMallDaySettlement,
    component: ModooMallDaySettlement,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-mall-day-settlement",
    title: "판매현황 입점몰 정산일자별",
  },
  //회원관리
  {
    path: URLs.ModooMember,
    component: ModooMember,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "modoo-member",
    title: "회원관리",
  },
  {
    path: URLs.ModooMemberDetail,
    component: ModooMemberDetail,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "modoo-member-detail",
    title: "회원 상세정보",
  },
  // 모두같이
  // 기본설정
  {
    path: URLs.MallSetting,
    component: MallSetting,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-setting",
    title: "기본설정",
  },
  //메인노출설정
  {
    path: URLs.MallMain,
    component: MallMain,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-main",
    title: "메인노출설정",
  },
  //커뮤니티관리
  {
    path: URLs.MallStory,
    component: MallStory,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-story",
    title: "스토리",
  },
  {
    path: URLs.MallStoryRegister,
    component: MallStoryRegister,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-story-register",
    title: "스토리등록",
  },
  {
    path: URLs.MallChannel,
    component: MallChannel,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-channel",
    title: "채널",
  },
  {
    path: URLs.MallChannelRegister,
    component: MallChannelRegister,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-channel-register",
    title: "채널등록",
  },
  {
    path: URLs.MallNews,
    component: MallNews,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-news",
    title: "새소식",
  },
  {
    path: URLs.MallNewsRegister,
    component: MallNewsRegister,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-news-register",
    title: "새소식등록",
  },
  //정산내역조회
  {
    path: URLs.MallSearchBalance,
    component: MallSearchBalance,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-search-balance",
    title: "정산내역조회",
  },
  //마케팅자료관리
  {
    path: URLs.MallMarketing,
    component: MallMarketing,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-marketing",
    title: "마케팅자료관리",
  },
  {
    path: URLs.MallMarketingRegister,
    component: MallMarketingRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "mall-marketing-register",
    title: "마케팅자료 등록",
  },
  //회원관리
  {
    path: URLs.MallMember,
    component: MallMember,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "mall-member",
    title: "회원관리",
  },
  {
    path: URLs.MallMemberDetail,
    component: MallMemberDetail,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "mall-member-detail",
    title: "회원 상세정보",
  },

  //공통관리
  //회사정보관리
  {
    path: URLs.CommonCompanyInfo,
    component: CommonCompanyInfo,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "common-company-info",
    title: "회사정보관리",
  },
  //임직원정보관리
  {
    path: URLs.CommonEmployee,
    component: CommonEmployee,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "common-employee",
    title: "입직원관리",
  },
  {
    path: URLs.CommonEmployeeRegister,
    component: CommonEmployeeRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "common-employee-register",
    title: "입직원관리 등록/수정",
  },
  {
    path: URLs.CommonRegisterAuthority,
    component: CommonRegisterAuthority,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "common-register-authority",
    title: "권한등록",
  },
  //팝업관리
  {
    path: URLs.CommonPopup,
    component: CommonPopup,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "common-popup",
    title: "팝업관리",
  },
  {
    path: URLs.CommonPopupRegister,
    component: CommonPopupRegister,
    routeCondition: "WHENEVER", // 현재 DB에 해당 url 정보가 없어서 일단 whenever 처리
    cssId: "common-popup-register",
    title: "팝업등록",
  },
  //SCM비밀번호초기화
  {
    path: URLs.CommonScmPasswordReset,
    component: CommonScmPasswordReset,
    routeCondition: "MUST_BE_SIGNED_IN",
    cssId: "common-scm-password-reset",
    title: "SCM 비밀번호 초기화",
  },
];

const PageRoutes = () => {
  return (
    <Router>
      <AsyncContainer suspenseFallback={<LoadingSpinner />}>
        <Routes>
          {routes.map((route, index) => {
            const {
              path,
              component: Component,
              routeCondition,
              cssId: id,
            } = route;
            switch (routeCondition) {
              case "WHENEVER":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <PageContainer id={id}>
                        <Component />
                      </PageContainer>
                    }
                  />
                );
              case "MUST_BE_SIGNED_IN":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <SignedInContainer>
                        <PageContainer id={id}>
                          <Component />
                        </PageContainer>
                      </SignedInContainer>
                    }
                  />
                );
              case "MUST_BE_SIGNED_OUT":
                return (
                  <Route
                    key={index}
                    path={path}
                    element={
                      <SignedOutContainer>
                        <PageContainer id={id}>
                          <Component />
                        </PageContainer>
                      </SignedOutContainer>
                    }
                  />
                );
            }
          })}
          <Route path="/invalid-approach" element={<InvalidApproach />} />
          <Route path="*" element={<InvalidApproach />} />
        </Routes>
      </AsyncContainer>
    </Router>
  );
};

export default PageRoutes;
