import { useCallback } from "react";

export const useSessionStorage = () => {
  const setItem = useCallback(
    (key: string, value: any) =>
      sessionStorage.setItem(key, JSON.stringify(value)),
    [],
  );

  const getItem = useCallback((key: string) => {
    const item = sessionStorage.getItem(key);
    if (!item) return item;
    return JSON.parse(item);
  }, []);

  const removeItem = useCallback(
    (key: string) => sessionStorage.removeItem(key),
    [],
  );

  return { setItem, getItem, removeItem };
};
