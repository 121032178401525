// 최근 검색어 저장
import { createStore } from "@methodjs/store";
import dayjs from "dayjs";

export const initSearchBookStoreState = () => ({
  searchParam: "",
  readOnlyYn: true,
  fromDate: dayjs(),
  toDate: dayjs(),
});

const [useSearchBookStore, setSearchBookStore, getSearchBookStore] =
  createStore(initSearchBookStoreState);

export { useSearchBookStore, setSearchBookStore, getSearchBookStore };
