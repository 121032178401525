import React from "react";
import { Modal } from "@mui/material";
import useModal from "hooks/useModal";

const ModalContainer = () => {
  const { open, ModalComponent, modal } = useModal();
  return (
    <Modal
      open={open}
      onClose={modal.close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div id="modal-container">{ModalComponent}</div>
    </Modal>
  );
};

export default ModalContainer;
