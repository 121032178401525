import React, { ReactNode } from "react";
import AsyncBoundary from "./AsyncBoundary";
import { ErrorFallbackProps } from "components/ErrorFallback";
import LoadingSpinner from "components/LoadingSpinner";
import ErrorPage from "pages/ErrorPage";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import useInitWithScrollToTop from "hooks/useInitWithScrollToTop";
import useLoading from "hooks/useLoading";
import useSearchBookInit from "hooks/useSearchBookInit";

interface PageContainerProps {
  id: string;
  children: ReactNode;
}

const PageContainer = ({ id, children }: PageContainerProps) => {
  useInitWithScrollToTop();
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  useLoading(isFetching > 0 || isMutating > 0);
  useSearchBookInit();

  return (
    <div className="page-container">
      <div id={id}>
        <AsyncBoundary
          suspenseFallback={<LoadingSpinner />}
          errorFallback={({ error, resetBoundary }: ErrorFallbackProps) => (
            <ErrorPage error={error} resetBoundary={resetBoundary} />
          )}
        >
          {children}
        </AsyncBoundary>
      </div>
    </div>
  );
};

export default PageContainer;
